import classNames from "classnames"
import SearchBox from "common/components/SearchBox"
import { RoundedButton } from "common/components/button/RoundedButton"
import { SaleValue, ServingSale } from "common/types"
import { cellBorderColor } from "common/ui/cells/config"
import DateInput from "common/ui/datepicker/DateInput"
import { SimpleTable2 } from "common/ui/simpleTable"
import { containsIgnoreCase } from "common/ui/utils"
import { endOfDay, startOfDay, startOfMonth } from "date-fns"
import { UserItem } from "models/user"
import { useEffect, useState } from "react"
import { Role } from "services/constants"
import { ReportService } from "services/reportService"
import { SaleAction } from "stores/actionStores/saleAction"
import { UserAction } from "stores/actionStores/userAction"
import { saleStore } from "stores/saleStore"
import { userStore } from "stores/userStore"
import styled from "styled-components"
import { useSnapshot } from "valtio"

export const SaleValueTable = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [fromDate, setFromDate] = useState(startOfMonth(new Date()))
    const [toDate, setToDate] = useState(new Date())
    const [saleValues, setSaleValues] = useState<SaleValue[]>([])
    const [searchText, setSearchText] = useState("")
    const { users } = useSnapshot(userStore)

    useEffect(() => {
        UserAction.fetchLatest()
    }, [])

    const handleRunReport = async () => {
        const from = startOfDay(fromDate).getTime()
        const to = endOfDay(toDate).getTime()
        const values = await ReportService.getSaleValueReport({ from, to })
        setSaleValues(values)
    }

    return (
        <div>
            {/* <div className="flex items-center gap-3 mb-3 mt-4">
                <SearchBox
                    rounded
                    className="w-80 bg-white"
                    value={searchText}
                    onChange={setSearchText}
                    placeholder="Gõ để tìm kiếm"
                />
            </div> */}

            <div className="flex items-center gap-5">
                <div className="w-[200px]">
                    <div className="uppercase font-medium text-xs mb-2 text-gray0">
                        Từ ngày
                    </div>
                    <DateInput selectedDate={fromDate} onSelect={setFromDate} />
                </div>
                <div className="w-[200px]">
                    <div className="uppercase font-medium text-xs mb-2 text-gray0">
                        Đến ngày
                    </div>
                    <DateInput selectedDate={toDate} onSelect={setToDate} />
                </div>
                <RoundedButton
                    onClick={handleRunReport}
                    className="mt-5"
                    primary
                >
                    Chạy báo cáo
                </RoundedButton>
            </div>
            <div className="mt-5">
                <SimpleTable2>
                    <thead className="card-shadow font-semibold text-sm">
                        <tr>
                            <td>STT</td>
                            <td className="">Sale</td>
                            <td className="">Mã NV</td>
                            <td className="">Doanh số</td>
                        </tr>
                    </thead>
                    <tbody className="text-sm">
                        {saleValues.map((e, index) => (
                            <tr
                                key={e.saleId}
                                className={classNames(
                                    "grid-cols-[56px,1fr,160px,160px,160px,50px]"
                                )}
                            >
                                <td>{index + 1}</td>
                                <td>
                                    {
                                        users.find((u) => u.id === e.saleId)
                                            ?.displayName
                                    }
                                </td>
                                <td>
                                    {users.find((u) => u.id === e.saleId)?.code}
                                </td>
                                <td>{e.total.formatCurrency()}</td>
                            </tr>
                        ))}
                    </tbody>
                </SimpleTable2>

                {/* {selectedItem != null && (
                        <EditUser
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            user={selectedItem}
                        />
                    )}
                    <AddUser isOpen={openAdd} setIsOpen={setOpenAdd} /> */}
            </div>
        </div>
    )
}

const Empty = styled.div`
    height: 78px;
    color: #dedede;
    border-bottom: 1px solid #e9e9e9;
    border-left: 1px solid ${cellBorderColor};
    border-right: 1px solid ${cellBorderColor};
`

const Cell = styled.div`
    border-right: 1px solid ${cellBorderColor};
    text-align: center;
    padding: 8px 8px;
`
