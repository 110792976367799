import { RegisterForm } from "common/types"
import API from "./api"
import { CommonResponse } from "models/response"

export const RegisterService = {
    async register(payload: RegisterForm) {
        var res = await API.post<CommonResponse>(`/register`, payload)
        return res.data
    },
}
