// @ts-ignore
import { CalendarVietnamese } from "date-chinese"

const isSameMonth = (d1: Date, d2: Date) => {
    return (
        d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear()
    )
}
const isSameDay = (d1: Date, d2: Date) => {
    return (
        d1.getDate() === d2.getDate() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getFullYear() === d2.getFullYear()
    )
}

const DateUtils = {
    fromYMDNumber(ymdNum: number) {
        let year = +`20${ymdNum.toString().substring(2, 0)}`
        let month = Number(`${ymdNum}`.substring(2, 4)) - 1
        let day = +`${ymdNum}`.substring(4, 6)
        return new Date(year, month, day)
    },
    getMondayInThePast(d: Date) {
        const clone = new Date(d)
        const day = clone.getDay(),
            diff = clone.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
        return new Date(clone.setDate(diff))
    },
    getSundayInFuture(d: Date) {
        const clone = new Date(d)
        const day = clone.getDay()
        if (day === 0) {
            return clone
        }

        const diff = 7 - day
        clone.setDate(clone.getDate() + diff)
        return clone
    },
    getLunarDate(d: Date) {
        const cal = new CalendarVietnamese()
        cal.fromGregorian(d.getFullYear(), d.getMonth() + 1, d.getDate())
        let [, , month, , day] = cal.get()
        let gyear = cal.yearFromEpochCycle()
        return {
            year: gyear,
            month,
            day,
        }
    },
    isSameDay,
    isSameMonth,
}

export default DateUtils
