import Sidebar from "common/components/Sidebar"
import { RoundedButton } from "common/components/button/RoundedButton"
import { Layout, Page } from "common/ui/styledComponents"
import { useEffect, useState } from "react"
import { bookingStore } from "stores/bookingStore"
import { useSnapshot } from "valtio"
import { getDatabase, off, onValue, ref } from "firebase/database"
import { AppConfig } from "services/appConfig"
import { BookingAction } from "stores/actionStores/bookingAction"
import { SaleAction } from "stores/actionStores/saleAction"
import DateInput from "common/ui/datepicker/DateInput"
import { useTheme } from "styled-components"
import { startOfMonth } from "date-fns"
import { SaleValueTable } from "./SaleValueTable"

export const SaleValueReport = () => {
    return (
        <Page id="events-page" className="flex-1 overflow-y-auto pb-10">
            <div className="px-8 py-4 sticky left-0">
                <div className="text-3xl text-primary font-semibold">
                    Báo cáo doanh thu
                </div>
            </div>
            <div className="mx-8">
                <SaleValueTable />
            </div>
        </Page>
    )
}
