import CaretDown from "modules/common/CaretDown"
import { useCallback } from "react"
import Select from "react-select"
import { userStore } from "stores/userStore"

import { useSnapshot } from "valtio"

type Option = {
    label: string
    value: number | null
}

export const SelectUser = ({
    userId,
    onChange,
    rounded,
    size,
}: {
    userId: number | null
    onChange: (id: number) => void
    rounded?: boolean
    size?: "sm"
}) => {
    const { users } = useSnapshot(userStore)

    const handleChange = (option: Option | null) => {
        onChange(option?.value || 0)
        // saveStatusDraft(option?.value || 0, currentEvent.id)
    }
    const options = users.map((e) => ({ label: e.displayName, value: e.id }))

    const value = userId ? options.find((e) => e.value === (userId || 0)) : null
    return (
        <>
            <Select
                className="text-sm min-w-[180px]"
                classNamePrefix={"react-select"}
                components={{
                    DropdownIndicator: CaretDown,
                }}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        minHeight: size == "sm" ? "32px" : "36px",
                        borderRadius: rounded ? "99px" : "3px",
                    }),
                }}
                value={value}
                options={options}
                isMulti={false}
                onChange={handleChange}
                placeholder="Tất cả"
                noOptionsMessage={() => "Không có kết quả"}
            />
        </>
    )
}
