import React from "react"

function IconSignout(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            width="1em"
            fill="none"
            viewBox="0 0 19 20"
            {...props}
        >
            <path
                fill="currentColor"
                d="M2 0h9a2 2 0 012 2v2a1 1 0 11-2 0V2.833A.833.833 0 0010.167 2H3a1 1 0 00-1 1v14a1 1 0 001 1h7.167c.46 0 .833-.373.833-.833V16a1 1 0 112 0v2a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2z"
            ></path>
            <path
                fill="currentColor"
                d="M14.146 12.515a.84.84 0 00.002 1.23c.357.34.936.34 1.294 0l3.29-3.128a.842.842 0 000-1.234l-3.29-3.128a.949.949 0 00-1.294 0 .84.84 0 00-.002 1.23l1.722 1.643H7.917c-.506 0-.917.39-.917.872s.41.872.917.872h7.95l-1.72 1.643z"
            ></path>
        </svg>
    )
}

export default IconSignout
