import classNames from "classnames"
import SearchBox from "common/components/SearchBox"
import { ServingSale } from "common/types"
import { cellBorderColor } from "common/ui/cells/config"
import { SimpleTable2 } from "common/ui/simpleTable"
import { containsIgnoreCase } from "common/ui/utils"
import { UserItem } from "models/user"
import { useEffect, useState } from "react"
import { Role } from "services/constants"
import { SaleAction } from "stores/actionStores/saleAction"
import { UserAction } from "stores/actionStores/userAction"
import { saleStore } from "stores/saleStore"
import { userStore } from "stores/userStore"
import styled from "styled-components"
import { useSnapshot } from "valtio"

export const SaleTable = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)

    const [searchText, setSearchText] = useState("")
    const { sales } = useSnapshot(saleStore)
    const { users } = useSnapshot(userStore)
    const [selectedItem, setSelectedItem] = useState<ServingSale>()
    const [showActive, setShowActive] = useState(true)
    const [activeId, setActiveId] = useState<number>(0)

    useEffect(() => {
        UserAction.fetchLatest()
        SaleAction.fetchLatest()
    }, [])

    const saleUsers = users
        .filter((u) => u.roleId === Role.SALE)
        .map((e) => e.id)

    const handleOpenChange = (openFlag: boolean, itemId: number) => {
        openFlag ? setActiveId(itemId) : setActiveId(0)
    }
    const handleAction = (action: string, item: UserItem) => {}
    const saleMans = sales
        .filter((e) => saleUsers.includes(e.saleId))
        .map((e) => ({
            ...e,
            name: users.find((u) => u.id === e.saleId)?.displayName ?? "",
            code: users.find((u) => u.id === e.saleId)?.code ?? "",
        }))
        .sort((a, b) => {
            if (a.servings.length === b.servings.length) {
                return b.pendings.length - a.pendings.length
            }
            return b.servings.length - a.servings.length
        })
    const result = saleMans.filter(
        (e) =>
            containsIgnoreCase(e.name, searchText) ||
            containsIgnoreCase(e.code, searchText)
    )
    return (
        <div>
            <div className="flex items-center gap-3 mb-3 mt-4">
                <SearchBox
                    rounded
                    className="w-80 bg-white"
                    value={searchText}
                    onChange={setSearchText}
                    placeholder="Gõ để tìm kiếm"
                />
            </div>
            <div>
                <SimpleTable2>
                    <thead className="card-shadow font-semibold text-sm">
                        <tr>
                            <td>STT</td>
                            <td className="">Sale</td>
                            <td className="">Mã NV</td>
                            <td className="">Đang chăm sóc</td>
                            <td className="">Đang chờ</td>
                        </tr>
                    </thead>
                    <tbody className="text-sm">
                        {result.map((e, index) => (
                            <tr
                                key={e.saleId}
                                className={classNames(
                                    "grid-cols-[56px,1fr,160px,160px,160px,50px]"
                                )}
                            >
                                <td>{index + 1}</td>
                                <td>
                                    {
                                        users.find((u) => u.id === e.saleId)
                                            ?.displayName
                                    }
                                </td>
                                <td>
                                    {users.find((u) => u.id === e.saleId)?.code}
                                </td>
                                <td>
                                    <div className="flex items-center justify-center">
                                        {e.servings.length ? (
                                            <div className="flex items-center text-xs text-white bg-[#2379EA] justify-center  w-6 h-6 rounded-full">
                                                {e.servings.length}
                                            </div>
                                        ) : (
                                            "-"
                                        )}
                                    </div>
                                </td>
                                <td>
                                    <div className="flex items-center justify-center">
                                        {e.pendings.length ? (
                                            <div className="flex items-center text-xs font-medium text-white bg-orange justify-center  w-6 h-6 rounded-full">
                                                {e.pendings.length}
                                            </div>
                                        ) : (
                                            "-"
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </SimpleTable2>
                {result.length == 0 && (
                    <Empty className="flex items-center justify-center text-sm ">
                        Không có dữ liệu
                    </Empty>
                )}
                {/* {selectedItem != null && (
                        <EditUser
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            user={selectedItem}
                        />
                    )}
                    <AddUser isOpen={openAdd} setIsOpen={setOpenAdd} /> */}
            </div>
        </div>
    )
}

const Empty = styled.div`
    height: 78px;
    color: #dedede;
    border-bottom: 1px solid #e9e9e9;
    border-left: 1px solid ${cellBorderColor};
    border-right: 1px solid ${cellBorderColor};
`

const Cell = styled.div`
    border-right: 1px solid ${cellBorderColor};
    text-align: center;
    padding: 8px 8px;
`
