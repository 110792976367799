import axios from "axios"
import { getAuth, getIdToken, onAuthStateChanged } from "firebase/auth"

const API = axios.create({
    baseURL:
        import.meta.env.MODE == "development"
            ? `http://localhost:5003/api`
            : "",
})
export const UPLOAD_API = axios.create({
    baseURL:
        import.meta.env.MODE == "development"
            ? `http://localhost:6900/api`
            : "",
})

API.defaults.headers.get["Accept"] = "application/json"
API.defaults.headers.post["Accept"] = "application/json"
API.defaults.headers.post["Content-Type"] = "application/json"
API.defaults.headers.put["Accept"] = "application/json"

const getUserToken = async () => {
    return new Promise((resolve, reject) => {
        const unsub = onAuthStateChanged(getAuth(), async (user) => {
            if (user) {
                const token = await getIdToken(user)
                resolve(token)
            } else {
                console.log("User not logged in")
                resolve(null)
            }
            unsub()
        })
    })
}

UPLOAD_API.interceptors.request.use(
    async function (config) {
        if (import.meta.env.MODE !== "development") {
            config.baseURL = `https://upload.quanlytiec.vn/api`
        }
        const token = await getUserToken()
        if (token) {
            config.headers["Authorization"] = "Bearer " + token
        } else {
        }
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)
API.interceptors.request.use(
    async function (config) {
        if (import.meta.env.MODE !== "development") {
            config.baseURL = `https://api.quanlytiec.vn/api`
        }
        const token = await getUserToken()
        if (token) {
            config.headers["Authorization"] = "Bearer " + token
        } else {
        }
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

API.interceptors.response.use(null, function (err) {
    if (err.response && err.response.status === 401) {
        console.log("unauthorize, redirect to login ")
        window.location.href = "/login"
    }
    if (err.code == "ERR_NETWORK") {
    }

    return Promise.reject(err)
})

const extractCompanyCode = () => {
    const s = window.location.hostname
    var match = s.match(/^(\w+)\.quanlytiec\.vn$/)
    if (match && match[1]) {
        return match[1]
    }
    return ""
}

export default API
