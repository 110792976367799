import { UserItem } from "models/user"
import API from "services/api"
import { asyncStorage } from "services/localStorageService"
import { UserService } from "services/userService"
import { userStore } from "stores/userStore"

const storageKey = "USERS"

export const UserAction = {
    async fetchLatest() {
        if (!userStore.users.length) {
            userStore.users = (await asyncStorage.getItem(storageKey)) || []
        }

        let tsList = userStore.users
            .map((e) => e.ts)
            .sort()
            .reverse()
        let ts = tsList.length ? tsList[0] : 0
        var result = await UserService.getLatest(ts)
        if (result && result.length) {
            var idList = result.map((e) => e.id)

            userStore.users = [
                ...result,
                ...userStore.users.filter((e) => !idList.includes(e.id)),
            ]
            let escapeProxy = userStore.users.map((e) => ({ ...e }))
            asyncStorage.setItem(storageKey, escapeProxy)
        }
    },
    async add(
        payload: Pick<
            UserItem,
            | "userName"
            | "displayName"
            | "password"
            | "roleId"
            | "phoneNumber"
            | "code"
        >
    ) {
        const response = await UserService.add(payload)
        if (!response.error) {
            // userStore.users.push(response.data as UserItem)
            this.fetchLatest()
        }
        return response
    },
}
