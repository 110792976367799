import { ReactComponent as Loader } from "assets/svgs/loader.svg"
import { forwardRef } from "react"
import styled from "styled-components"

const BaseButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    min-width: 64px;
    padding: 8px 8px;
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s;
`

type ButtonProps = React.ComponentPropsWithoutRef<"button"> & {
    outline?: boolean
    brand?: boolean
    size?: "sm" | "lg"
    disabled?: boolean
    loading?: boolean
}
export type Ref = HTMLButtonElement
export const Button = forwardRef<Ref, ButtonProps>(
    (
        {
            size,
            outline,
            brand,
            children,
            disabled,
            loading,
            ...others
        }: ButtonProps,
        ref
    ) => {
        return (
            <StyledButton
                size={size}
                outline={outline}
                brand={brand}
                disabled={disabled}
                ref={ref}
                {...others}
            >
                {loading ? <Loader className="spinner" /> : children}
            </StyledButton>
        )
    }
)

export const StyledButton = styled(BaseButton)<{
    size?: "sm" | "lg"
    outline?: boolean
    brand?: boolean
}>`
    &.pointer-events-none {
        pointer-events: none;
    }
    border: ${(props) =>
        props.outline
            ? props.brand
                ? "1px solid var(--brand)"
                : "1px solid var(--input-border)"
            : "none"};
    background-color: ${(props) =>
        props.outline
            ? props.brand
                ? "#fff"
                : "#fff"
            : props.brand
            ? "var(--brand)"
            : "var(--primary)"};
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    &:disabled {
        opacity: 0.2;
        pointer-events: none;
    }
    height: ${(props) =>
        props.size == "sm" ? "26px" : props.size == "lg" ? "40px" : "32px"};
    color: ${(props) =>
        props.outline ? (props.brand ? "var(--brand)" : "#818181") : "white"};
    font-size: ${(props) =>
        props.size == "sm" ? "12px" : props.size == "lg" ? "14px" : "13px"};

    &:hover {
        background-color: ${(props) =>
            props.outline
                ? props.brand
                    ? "var(--brand-outline-hover)"
                    : "#FAFAFA"
                : props.brand
                ? "var(--brand-hover)"
                : "var(--primary-hover)"};
        color: ${(props) =>
            props.outline
                ? props.brand
                    ? "var(--brand)"
                    : "#818181"
                : "white"};
    }

    padding: ${(props) => (props.size == "sm" ? "8px" : "8px 16px")};

    &:active {
        background-color: ${(props) =>
            props.outline
                ? props.brand
                    ? "var(--brand-outline-active)"
                    : "#F5F5F5"
                : props.brand
                ? "var(--brand-active)"
                : "var(--primary-active)"};
    }

    /* &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgb(164,202,254, 0.45);
        position: relative;
        z-index: 1;
    } */
    transition: all 0.3s;
`
