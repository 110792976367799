import { ErrorMessage } from "common/components/ErrorMesssage"
import { Button } from "common/components/button/Button"
import Dialog from "common/ui/dialog/Dialog"
import DialogTitle from "common/ui/dialog/DialogTitle"
import { Input } from "common/ui/styledComponents"
import { UserItem } from "models/user"
import { useEffect, useState } from "react"
import { Role } from "services/constants"
import { UserAction } from "stores/actionStores/userAction"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}
export const AddUser = ({ isOpen, setIsOpen }: Props) => {
    // const { checklistNames } = useSnapshot(todoStore)
    const [displayName, setDisplayName] = useState("")
    const [userName, setUserName] = useState("")
    const [code, setCode] = useState("")
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState("")
    const [repass, setRepass] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [roleId, setRoleId] = useState(Role.SALE)

    useEffect(() => {}, [])

    const validPass = password && password.trim() && password.length >= 6
    const validRepass = password === repass
    const validUserName = userName && userName.trim() && userName.length >= 3
    const validDisplayName =
        displayName && displayName.trim() && displayName.length >= 3

    const validateForm = () => {
        let isValid = true
        if (!validPass) {
            setErrorMessage("Mật khẩu không hợp lệ")
            isValid = false
        }
        if (!validRepass) {
            setErrorMessage("Mật khẩu không khớp")
            isValid = false
        }
        if (!validUserName) {
            setErrorMessage("Tên đăng nhập không hợp lệ")
            isValid = false
        }
        if (!validDisplayName) {
            setErrorMessage("Tên hiển thị không hợp lệ")
            isValid = false
        }
        return isValid
    }
    const onSaleChange = () => {
        setRoleId(Role.SALE)
    }
    const onManagerChange = () => {
        setRoleId(Role.MANAGER)
    }

    const handleSave = async () => {
        const isValid = validateForm()
        if (!isValid) {
            return
        }
        setLoading(true)
        let payload: Pick<
            UserItem,
            | "userName"
            | "displayName"
            | "password"
            | "roleId"
            | "phoneNumber"
            | "code"
        > = {
            userName,
            displayName,
            roleId,
            password,
            code,
            phoneNumber: "",
        }
        setLoading(true)
        let response = await UserAction.add(payload)
        setLoading(false)
        if (!response.error) {
            setIsOpen(false)
        } else {
            setErrorMessage(response.message || "")
        }
        // let response = await MaterialAction.updateMaterialGroup({
        //     id: unit.id,
        //     name,
        //     code,
        // })
        setLoading(false)
        // if (!response.error) {
        //     setIsOpen(false)
        // }
    }
    const handleChangeRole = (ev: React.FormEvent<HTMLFormElement>) => {
        console.log(ev)
    }
    return (
        <Dialog
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            closeOnClickBackdrop={false}
        >
            <div className="m-auto scaleIn shadow-menu min-h-[100px] rounded-lg  w-[500px] mt-[5vh] bg-white">
                <DialogTitle>Thêm tài khoản</DialogTitle>
                <div className=" pt-4 pb-4 px-12 relative">
                    <div className="font-bold mb-2 uppercase text-xs">
                        Loại tài khoản
                    </div>
                    <form onChange={handleChangeRole}>
                        <div className="flex items-center text-sm pt-1">
                            <label className="">
                                <input
                                    className="mr-3"
                                    type="radio"
                                    name="role"
                                    checked={roleId === Role.SALE}
                                    onChange={onSaleChange}
                                />
                                Sale{" "}
                            </label>
                            <label className="ml-7">
                                <input
                                    className="mr-3"
                                    type="radio"
                                    name="role"
                                    checked={roleId === Role.MANAGER}
                                    onChange={onManagerChange}
                                />
                                Quản lý{" "}
                            </label>
                        </div>
                    </form>
                    <div className="font-bold mb-2 uppercase text-xs mt-5">
                        Tên hiển thị
                    </div>
                    <Input
                        className="text-md bg-white"
                        placeholder={"Nhập tên hiển thị"}
                        value={displayName}
                        autoFocus={true}
                        onChange={(e) => setDisplayName(e.target.value)}
                    />
                    <div className="font-bold mb-2 uppercase text-xs mt-5">
                        Mã nhân viên
                    </div>
                    <Input
                        className="text-md bg-white"
                        placeholder={"Nhập mã nhân viên"}
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                    />
                    <div className="font-bold mb-2 uppercase text-xs mt-5">
                        Tên đăng nhập
                    </div>
                    <Input
                        className="text-md bg-white"
                        placeholder={"Nhập tên đăng nhập"}
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                    />
                    <div className="font-bold mb-2 uppercase text-xs mt-5">
                        Mật khẩu
                    </div>
                    <Input
                        type="password"
                        className="text-md bg-white"
                        placeholder={"Nhập mật khẩu tối thiểu 6 ký tự"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="font-bold mb-2 uppercase text-xs mt-5">
                        Nhập lại mật khẩu
                    </div>
                    <Input
                        type="password"
                        className="text-md bg-white"
                        placeholder={"Nhập lại mật khẩu"}
                        value={repass}
                        onChange={(e) => setRepass(e.target.value)}
                    />
                    {errorMessage && <ErrorMessage content={errorMessage} />}

                    <div className="flex items-center gap-5 justify-end mt-8">
                        <Button
                            onClick={handleSave}
                            loading={loading}
                            disabled={
                                !displayName.trim() ||
                                !validUserName ||
                                !validPass
                            }
                            className="w-24 "
                        >
                            Lưu
                        </Button>
                        <Button
                            outline
                            className="border-none shadow-none"
                            onClick={() => setIsOpen(false)}
                        >
                            Hủy
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
