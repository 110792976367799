import styled from "styled-components"

export const SimpleTable = styled.table`
    border-collapse: collapse;
    width: 100%;
    thead tr {
        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
        td {
            border-left: 1px solid var(--border-color);
            &:first-child {
                border-left: none;
            }
            padding: 8px 12px;
        }
        height: 44px;
    }
    td {
        text-align: center;
        vertical-align: middle;
        color: #333;
    }

    tbody td {
        padding: 12px 12px;
    }
    tbody tr + tr {
        border-top: 1px solid var(--border-color);
    }
    tbody {
        tr:hover {
            background-color: #f6f6f6;
        }
    }
`
export const SimpleTable2 = styled.table`
    border-collapse: collapse;
    width: 100%;
    thead tr {
        background-color: #546f7a;
        box-shadow: 0 3px 3px -2px #c6c6c6;
        td {
            &:first-child {
                border-left: 1px solid #546f7a;
            }
            color: #f6f6f6;
            padding: 8px 12px;
            &:last-child {
                border-right: 1px solid #546f7a;
            }
        }
        height: 44px;
    }
    td {
        text-align: center;
        vertical-align: middle;
        border-left: 1px solid var(--border-color);
        &:last-child {
            border-right: 1px solid var(--border-color);
        }
    }

    tbody td {
        padding: 12px 12px;
        color: #333;
    }
    tbody tr + tr {
        border-top: 1px solid var(--border-color);
    }
    tbody {
        tr:hover {
            background-color: #f6f6f6;
        }
        border-bottom: 1px solid var(--border-color);
    }
`
