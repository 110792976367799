import { PurpleBall } from "common/components/balls"
import IconChevronRight from "common/icons/IconChevronRight"
import IconLock from "common/icons/IconLock"
import { Page } from "common/ui/styledComponents"
import { ReactNode } from "react"
import { NavLink } from "react-router-dom"
import { AppRoutes } from "services/constants"

export const ReportLayout = () => {
    return (
        <Page className="flex-1 overflow-y-auto">
            <div className="px-8 py-4">
                <div className="flex items-center text-[11px] font-medium">
                    <div className="text-blue">Trang chủ</div>
                    <span className="text-[#ccc] mx-2">//</span>
                    <span>Báo cáo</span>
                </div>
                <div className="text-3xl text-primary mt-4 font-semibold">
                    Báo cáo
                </div>
                <div className="flex mt-10">
                    <div className="flex-1">
                        <div className="flex items-center gap-3">
                            <PurpleBall className="w-7 h-7 rounded-full grid place-content-center flex-shrink-0">
                                <IconLock className="text-white text-xs" />
                            </PurpleBall>
                            <div className="text-base font-semibold">
                                Báo cáo chung
                            </div>
                        </div>
                        <hr className="mt-4" />
                        <div className="flex items-center text-sm gap-3 mt-4">
                            <IconChevronRight className="text-gray1 text-xs" />
                            <NavLink
                                className={"hover:underline"}
                                to={`${AppRoutes.REPORT}/sales-value`}
                            >
                                Báo cáo doanh thu
                            </NavLink>
                        </div>
                        {/* <div className="flex items-center gap-3 mt-20">
                            <OrangeBall className="w-7 h-7 rounded-full grid place-content-center flex-shrink-0">
                                <IconSetting className="text-white text-xs" />
                            </OrangeBall>
                            <div className="text-base font-semibold">
                                Cài đặt ứng dụng
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </Page>
    )
}

const SettingItem = ({
    className,
    path,
    children,
}: {
    className?: string
    path: string
    children: ReactNode
}) => {
    return (
        <div className="flex items-center text-sm gap-3 mt-4">
            <IconChevronRight className="text-gray1 text-xs" />
            <NavLink className={"hover:underline"} to={path}>
                {children}
            </NavLink>
        </div>
    )
}
