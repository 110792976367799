import { LoadingDialog } from "common/components/LoadingDialog"
import { SelectDateRange } from "common/components/SelectDateRange"
import SelectLastNDays from "common/components/SelectLastNDays"
import { SelectLastNDays2 } from "common/components/SelectLastNDays2"
import Sidebar from "common/components/Sidebar"
import { IconButtonDropdown } from "common/components/button/IconButtonDropdown"
import { RoundedButton } from "common/components/button/RoundedButton"
import IconCaretDown from "common/icons/IconCaretDown"
import { SimpleTable2 } from "common/ui/simpleTable"
import { Layout, Page } from "common/ui/styledComponents"
import { startOfDay, subDays } from "date-fns"
import { getDatabase, off, onValue, ref } from "firebase/database"
import User from "models/user"
import { useEffect, useState } from "react"
import { AppConfig } from "services/appConfig"
import { BookingStatus, Role } from "services/constants"
import { BookingStatusBadge } from "shared_components/BookingStatusBadge"
import { SelectUser } from "shared_components/SelectUser"
import { BookingAction } from "stores/actionStores/bookingAction"
import { UserAction } from "stores/actionStores/userAction"
import { bookingStore } from "stores/bookingStore"
import { rootStore } from "stores/rootStore"
import { userStore } from "stores/userStore"
import styled from "styled-components"
import DateUtils from "utils/DateUtils"
import { snapshot, useSnapshot } from "valtio"
import { RegisterGuest } from "./RegisterGuest"

export const Bookings = () => {
    const [openAdd, setOpenAdd] = useState(false)
    const [globalLoading, setGlobalLoading] = useState(false)
    const { bookings } = useSnapshot(bookingStore)
    const { currentUser } = useSnapshot(rootStore)
    const { users } = useSnapshot(userStore)
    const [saleId, setSaleId] = useState(0)

    //3 ngày gần đây
    const [dateRange, setDateRange] = useState([
        startOfDay(subDays(new Date(), 3)).getTime(),
        new Date().getTime(),
    ])
    useEffect(() => {
        bookingStore.dateRage = dateRange
        BookingAction.getBookings()
        UserAction.fetchLatest()
        const db = getDatabase()
        const saleBookingRef = ref(db, `/companies/gonow/saleBooking`)
        onValue(saleBookingRef, (snapshot) => {
            const saleId = snapshot.val()
            BookingAction.getBookings()
            // if (saleId === AppConfig.userId) {
            // }
            console.log("saleId: " + saleId)
        })
    }, [dateRange])
    useEffect(() => {
        const db = getDatabase()
        const saleBookingRef = ref(db, `/companies/gonow/saleBooking`)
        return () => {
            off(saleBookingRef)
        }
    }, [])

    return (
        <Layout>
            <Sidebar />
            <Page id="events-page" className="flex-1 overflow-y-auto pb-10">
                <div className="px-8 py-4 sticky left-0">
                    <div className="text-3xl text-primary font-semibold">
                        Booking
                    </div>
                    <div className="flex items-center mt-4 ">
                        <div className="flex items-center gap-3">
                            <RoundedButton
                                primary
                                onClick={() => setOpenAdd(true)}
                            >
                                Thêm
                            </RoundedButton>
                        </div>
                    </div>
                </div>

                <div className="px-8 mt-5 flex sticky left-0">
                    <div className="flex-1">
                        <div className="flex items-center gap-5">
                            <div className="flex items-center">
                                <span className="text-xs font-medium text-[#949494] mr-3">
                                    THỜI GIAN:
                                </span>
                                <SelectDateRange
                                    range={dateRange}
                                    onChange={setDateRange}
                                />
                            </div>
                            {currentUser.roleId === Role.MANAGER && (
                                <div className="flex items-center">
                                    <span className="text-xs font-medium text-[#949494] mr-3">
                                        SALE:
                                    </span>
                                    <SelectUser
                                        userId={saleId}
                                        onChange={setSaleId}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="mt-5"></div>
                    </div>
                </div>
                <div className="mx-8 mt-1">
                    <SimpleTable2>
                        <thead className="font-semibold text-sm card-shadow-3 z-10">
                            <tr>
                                <td>Tên khách hàng</td>
                                <td className="">Số điện thoại</td>
                                <td className="">Email</td>
                                <td className="">Thời gian</td>
                                <td className="">Checkin</td>
                                <td className="">Checkout</td>
                                <td className="">Người lớn</td>
                                <td className="">Trẻ em</td>
                                <td className="">Sale</td>
                                <td className="">Trạng thái</td>
                                <td className="">Giá gói</td>
                                <td className="">&nbsp;</td>
                            </tr>
                        </thead>
                        <tbody className="text-sm">
                            {bookings.map((e) => (
                                <tr key={e.id}>
                                    <td>{e.name}</td>
                                    <td>{e.phoneNumber}</td>
                                    <td>{e.email}</td>
                                    <td>
                                        {new Date(e.receivedAt).formatDMY()}{" "}
                                        {new Date(e.receivedAt).formatHHmm()}
                                    </td>
                                    <td>
                                        {DateUtils.fromYMDNumber(
                                            e.checkinDate
                                        ).formatDMY()}
                                    </td>
                                    <td>
                                        {DateUtils.fromYMDNumber(
                                            e.checkoutDate
                                        ).formatDMY()}
                                    </td>
                                    <td>{e.adults}</td>
                                    <td>{e.children}</td>
                                    <td>
                                        {
                                            users.find((u) => u.id === e.saleId)
                                                ?.displayName
                                        }
                                    </td>
                                    <td>
                                        <BookingStatusBadge status={e.status} />
                                    </td>
                                    <td>{e.price.formatCurrency()}</td>
                                    <td>
                                        <IconButtonDropdown
                                            onAction={async (
                                                action: string
                                            ) => {
                                                if (action === "serve") {
                                                    setGlobalLoading(true)
                                                    await BookingAction.serveBooking(
                                                        e.id
                                                    )
                                                    setGlobalLoading(false)
                                                }
                                                if (action === "book") {
                                                    setGlobalLoading(true)
                                                    await BookingAction.setBooked(
                                                        e.id
                                                    )
                                                    setGlobalLoading(false)
                                                }
                                            }}
                                            menus={
                                                e.status ===
                                                BookingStatus.ASSIGNED
                                                    ? [
                                                          {
                                                              icon: null,
                                                              key: "serve",
                                                              label: "Tiếp nhận",
                                                          },
                                                      ]
                                                    : e.status ===
                                                      BookingStatus.SERVING
                                                    ? [
                                                          {
                                                              icon: null,
                                                              key: "book",
                                                              label: "Chốt",
                                                          },
                                                      ]
                                                    : []
                                            }
                                            tooltip="Thao tác"
                                            icon={
                                                <IconCaretDown className="text-[8px]" />
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </SimpleTable2>
                </div>
                <div id="fake-scroll">&nbsp;</div>
            </Page>
            <RegisterGuest isOpen={openAdd} setIsOpen={setOpenAdd} />
            <LoadingDialog
                loading={globalLoading}
                setLoading={setGlobalLoading}
            />
        </Layout>
    )
}
