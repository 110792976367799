import {
    FloatingFocusManager,
    FloatingOverlay,
    FloatingPortal,
    autoPlacement,
    offset,
    useDismiss,
    useFloating,
    useInteractions,
} from "@floating-ui/react"
import classNames from "classnames"
import { useClickOutside } from "common/ui/hooks"
import Tooltip from "modules/common/Tooltip"
import { ReactNode, useCallback, useEffect, useState } from "react"
import styled from "styled-components"

type Props = {
    onClick?: () => void
    tooltip?: string
    icon: ReactNode
    className?: string
    size?: "lg"
    menus: { key: string; label: string; icon: ReactNode }[]
    onAction: (key: string) => void
    onOpenChange?: (isOpen: boolean) => void
}
export const IconButtonDropdown = ({
    className,
    tooltip,
    icon,
    size,
    onOpenChange,
    menus,
    onAction,
}: Props) => {
    const [isOpen, setIsOpen] = useState(false)
    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            autoPlacement({
                allowedPlacements: [
                    "bottom-start",
                    "bottom-end",
                    "top-start",
                    "top-end",
                ],
            }),
            offset(10),
        ],
    })

    const dismiss = useDismiss(context, {
        outsidePress: false,
    })
    const { getReferenceProps } = useInteractions([dismiss])

    const handleClickOutside = useCallback(() => {
        setTimeout(() => {
            // setIsOpen(false)
        }, 200)
    }, [])
    useClickOutside(handleClickOutside, refs.domReference, refs.floating)

    useEffect(() => {
        onOpenChange && onOpenChange(isOpen)
    }, [isOpen])
    return (
        <>
            <Tooltip content={tooltip || ""}>
                <div
                    tabIndex={0}
                    ref={refs.setReference}
                    onClick={() => setIsOpen((s) => !s)}
                    className={classNames(
                        "rounded-full cursor-pointer outline-none select-none bg-[#eee] grid place-content-center",
                        size == "lg" ? " h-8" : "w-6 h-6 ",
                        className
                    )}
                    {...getReferenceProps()}
                >
                    {icon}
                </div>
            </Tooltip>
            {isOpen && (
                <FloatingPortal>
                    <FloatingOverlay
                        className="z-[100]"
                        onClick={() => setIsOpen(false)}
                    >
                        <MenuDropdown
                            ref={refs.setFloating}
                            style={floatingStyles}
                            onClick={() => setIsOpen(false)}
                            className="shadow-menu bg-white select-none"
                        >
                            {menus.map((e) => (
                                <div
                                    key={e.key}
                                    className="menu-item"
                                    onClick={() => onAction(e.key)}
                                >
                                    {e.icon} {e.label}
                                </div>
                            ))}
                        </MenuDropdown>
                    </FloatingOverlay>
                </FloatingPortal>
            )}
        </>
    )
}

const MenuDropdown = styled.div`
    padding: 4px 0;
    border-radius: 3px;
    .menu-item {
        padding: 8px 20px;
        min-width: 160px;
        font-size: 14px;
        &:hover {
            background-color: #f3f3f3;
            cursor: pointer;
        }
    }
`
