import Sidebar from "common/components/Sidebar"
import { Layout } from "common/ui/styledComponents"
import { Outlet } from "react-router-dom"

export const Report = () => {
    return (
        <Layout>
            <Sidebar />
            <Outlet />
        </Layout>
    )
}
