import { asyncStorage } from "services/localStorageService"
import { SaleService } from "services/saleService"
import { saleStore } from "stores/saleStore"
import { ObjectUtils } from "utils/ObjectUtils"

const storageKey = "SALES"

export const SaleAction = {
    async fetchLatest() {
        if (!saleStore.sales.length) {
            saleStore.sales = (await asyncStorage.getItem(storageKey)) || []
        }

        let tsList = saleStore.sales
            .map((e) => e.ts)
            .sort()
            .reverse()
        let ts = tsList.length ? tsList[0] : 0
        var result = await SaleService.getLatest(ts)
        if (result && result.length) {
            var idList = result.map((e) => e.saleId)

            saleStore.sales = [
                ...result,
                ...saleStore.sales.filter((e) => !idList.includes(e.saleId)),
            ]
            let escapeProxy = ObjectUtils.cloneDeep(saleStore.sales)
            asyncStorage.setItem(storageKey, escapeProxy)
        }
    },
}
