import { BookingService } from "services/bookingService"
import { bookingStore } from "stores/bookingStore"

export const BookingAction = {
    async getBookings() {
        const [from, to] = bookingStore.dateRage
        const result = await BookingService.getBookings({ from, to })
        bookingStore.bookings = result
    },
    async serveBooking(bookingId: string) {
        const response = await BookingService.serveBooking(bookingId)
        if (!response.error) {
            this.getBookings()
        }
    },
    async setBooked(bookingId: string) {
        const response = await BookingService.setBooked(bookingId)
        if (!response.error) {
            this.getBookings()
        }
    },
}
