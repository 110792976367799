import "App.scss"
import { styled } from "styled-components"

import { getAuth, onAuthStateChanged } from "firebase/auth"
import { UserItem } from "models/user"
import Notification from "modules/common/Notification"
import "override.scss"
import { useEffect, useState } from "react"
import "react-quill/dist/quill.snow.css"
import { Outlet } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import API from "services/api"
import { SIDEBAR_WIDTH } from "services/constants"
import { rootStore } from "stores/rootStore"
import { useSnapshot } from "valtio"
import { MessagingAction } from "stores/actionStores/messagingAction"
import { uiStore } from "stores/uiStore"
import { AppConfig } from "services/appConfig"
// import CellProvider from 'core/cells/CellProvider';

function Layout() {
    const [loading, setLoading] = useState(true)
    const { fcmToken } = useSnapshot(rootStore)

    useEffect(() => {
        const unsub = onAuthStateChanged(getAuth(), async (user) => {
            if (user) {
                //force refresh
                // var idToken = await getIdToken(user, true)

                window.addEventListener("focus", function () {}, false)

                API.get<UserItem>("/users/me").then((res) => {
                    rootStore.currentUser = res.data as UserItem
                    AppConfig.userId = res.data ? res.data.id || 0 : 0
                    setTimeout(() => {
                        setLoading(false)
                    }, 100)
                })
            } else {
                window.location.href = "/login"
            }
            unsub()
        })

        return () => {
            console.log("..... out")
        }
    }, [])

    useEffect(() => {
        if (fcmToken) {
            MessagingAction.registerClient(fcmToken)
        }
    }, [fcmToken])
    const setGlobalLoading = (flag: boolean) => {
        uiStore.loading = flag
    }
    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Outlet />
                    <ToastContainer
                        // position="bottom-right"
                        // transition={Slide}
                        hideProgressBar
                        // newestOnTop
                        closeOnClick
                        // pauseOnFocusLoss
                        // draggable
                        // pauseOnHover
                        // theme="colored"
                        stacked
                    />
                    <Notification />
                </>
            )}
        </>
    )
}

export default Layout

const Main = styled.div`
    width: calc(100vw - ${SIDEBAR_WIDTH}px);
    height: 100vh;
    position: fixed;
    top: 0;
    left: ${SIDEBAR_WIDTH}px;
    min-height: 0;
    background-color: #fff;
`

// const TopHeader = styled.div`
//   height: 60px;
//   border-bottom: 1px solid #eee;
// `
const Loader = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
`
