import { Button } from "common/components/button/Button"
import Dialog from "common/ui/dialog/Dialog"
import DialogTitle from "common/ui/dialog/DialogTitle"
import { Input } from "common/ui/styledComponents"
import { UserItem } from "models/user"
import { useEffect, useState } from "react"

type Props = {
    isOpen: boolean
    user: UserItem
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const EditUser = ({ isOpen, setIsOpen, user }: Props) => {
    // const { checklistNames } = useSnapshot(todoStore)
    const [name, setName] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setName(user.displayName)
    }, [user, isOpen])

    const handleSave = async () => {
        // if (!checklistNames.includes(name)) {
        //     todoStore.checklistNames.push(name)
        //     todoStore.currentChecklist = name
        // }
        // let response = await Materia.
        setLoading(true)
        // let response = await MaterialAction.updateMaterialGroup({
        //     id: unit.id,
        //     name,
        //     code,
        // })
        setLoading(false)
        // if (!response.error) {
        //     setIsOpen(false)
        // }
    }

    const notChanged = name.trim() === user.displayName

    return (
        <Dialog
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            closeOnClickBackdrop={false}
        >
            <div className="m-auto scaleIn shadow-menu min-h-[100px] rounded-lg  w-[500px] mt-[15vh] bg-white">
                <DialogTitle>Sửa nhóm nguyên liệu</DialogTitle>
                <div className=" pt-4 pb-4 px-12 relative">
                    <div className="font-bold mb-2 uppercase text-xs">
                        Tên hiển thị
                    </div>
                    <Input
                        className="text-md bg-white"
                        placeholder={user.displayName}
                        value={name}
                        autoFocus={true}
                        onChange={(e) => setName(e.target.value)}
                    />

                    <div className="flex items-center gap-5 justify-end mt-8">
                        <Button
                            onClick={handleSave}
                            loading={loading}
                            disabled={!name.trim() || notChanged}
                            className="w-24 "
                        >
                            Lưu
                        </Button>
                        <Button
                            outline
                            className="border-none shadow-none"
                            onClick={() => setIsOpen(false)}
                        >
                            Hủy
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default EditUser
