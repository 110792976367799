// @ts-ignore
import { CalendarVietnamese } from "date-chinese"

export const stripAccents = (str: string) => {
    return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/đ/g, "d")
        .replace(/Đ/g, "D")
}

export const containsIgnoreCase = (s: string, s1: string) => {
    s = stripAccents(s).toLowerCase().trim()
    s1 = stripAccents(s1).toLowerCase().trim()
    let result =  s.startsWith(s1) || s.includes(` ${s1}`)
    return result
}

export const isSameMonth = (d1: Date, d2: Date) => {
    return (
        d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear()
    )
}
export const isSameDay = (d1: Date, d2: Date) => {
    return (
        d1.getDate() === d2.getDate() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getFullYear() === d2.getFullYear()
    )
}

