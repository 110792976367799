import {
    FloatingFocusManager,
    FloatingOverlay,
    useFloating,
    FloatingPortal,
    useDismiss,
    useInteractions,
} from "@floating-ui/react"
import { ReactNode, useEffect, useState } from "react"
import { styled } from "styled-components"
import { createContext } from "react"
import React from "react"
import classNames from "classnames"
import FocusTrap from "focus-trap-react"

export const dialogContext = createContext<{
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}>({
    isOpen: false,
    setIsOpen: () => {},
})

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    children: ReactNode
    closeOnClickBackdrop?: boolean
    escapeToClose?: boolean
    overlayZIndex?: number
}

const Dialog = ({
    isOpen,
    setIsOpen,
    closeOnClickBackdrop = true,
    children,
    overlayZIndex,
    escapeToClose = true,
}: Props) => {
    const { refs, context } = useFloating<HTMLDivElement>({
        open: isOpen,
        onOpenChange: setIsOpen,
    })

    const dismiss = useDismiss(context, {
        outsidePress: false,
    })
    const { getReferenceProps, getFloatingProps } = useInteractions([dismiss])

    return (
        <dialogContext.Provider
            value={{ isOpen: isOpen, setIsOpen: setIsOpen }}
        >
            <FloatingPortal>
                {isOpen && (
                    <StyledFloatingOverlay
                        tabIndex={0}
                        ref={refs.setReference}
                        {...getReferenceProps()}
                        className={classNames(
                            " fade-in outline-none ",
                            overlayZIndex ? "" : "z-[100]"
                        )}
                        lockScroll
                        onMouseDown={(ev) => {
                            ev.preventDefault()
                            ev.stopPropagation()
                        }}
                        onClick={() =>
                            closeOnClickBackdrop ? setIsOpen(false) : null
                        }
                        style={{
                            background: "rgba(0, 0, 0, 0.2)",
                            zIndex: overlayZIndex ? overlayZIndex : 100,
                        }}
                    >
                        <div
                            className="m-auto w-fit outline-none"
                            onMouseDown={(e) => e.stopPropagation()}
                            onClick={(e) => e.stopPropagation()}
                        >
                            {children}
                        </div>
                    </StyledFloatingOverlay>
                )}
            </FloatingPortal>
        </dialogContext.Provider>
    )
}

export default Dialog

const StyledFloatingOverlay = styled(FloatingOverlay)`
    transition: all 0.25s ease;
`
