import {
    FloatingPortal,
    autoPlacement,
    autoUpdate,
    offset,
    useFloating,
} from "@floating-ui/react"
import classNames from "classnames"
import IconCaretDown from "common/icons/IconCaretDown"
import { CalendarDialog } from "common/ui/datepicker/CalendarDialog"
import { useClickOutside } from "common/ui/hooks"
import {
    addDays,
    differenceInCalendarDays,
    endOfDay,
    isSameDay,
    startOfDay,
    subDays,
} from "date-fns"
import { useState } from "react"
import styled from "styled-components"

type Props = {
    range: number[] //[from, to]
    className?: string
    onChange: (range: number[]) => void
}

//filter  dành cho start_time
export const SelectDateRange = ({ range, className, onChange }: Props) => {
    const [showDropdown, setShowDropdown] = useState(false)
    const [openCalendar, setOpenCalendar] = useState(false)
    const [from, to] = range

    const { refs, floatingStyles, update } = useFloating({
        open: showDropdown,
        onOpenChange: setShowDropdown,
        whileElementsMounted: autoUpdate,
        middleware: [
            autoPlacement({
                allowedPlacements: ["top", "bottom"],
            }),
            ,
            offset(10),
        ],
    })

    const handleSelectDate = (d: Date) => {
        const from = startOfDay(d).getTime()
        const to = endOfDay(d).getTime()
        onChange([from, to])
        setOpenCalendar(false)
    }

    const handleSelect = (value: number) => {
        if (value === -1) {
            setOpenCalendar(true)
        } else {
            let dateFrom = subDays(new Date(), value)
            let from = startOfDay(dateFrom).getTime()
            let to = new Date().getTime()
            onChange([from, to])
        }
    }
    const handleClickDropdown = () => {}

    const handleClickOutside = () => {
        setShowDropdown(false)
    }
    useClickOutside(handleClickOutside, refs.domReference, refs.floating)

    const getDateText = () => {
        const today = new Date()
        const fromDate = new Date(from)
        const toDate = new Date(to)
        if (!isSameDay(toDate, today)) {
            return toDate.formatDMY()
        }
        let diff = differenceInCalendarDays(toDate, fromDate)
        if (diff === 0) {
            return "Hôm nay"
        }
        return `trong ${diff} ngày gần đây`
    }

    const date = isSameDay(new Date(from), new Date(to))
        ? new Date(from)
        : new Date()
    return (
        <div
            ref={refs.setReference}
            onClick={() => setShowDropdown((s) => !s)}
            className={classNames(
                "inline-flex select-none whitespace-nowrap cursor-pointer items-center py-1 rounded-full text-sm",
                className
            )}
        >
            {getDateText()}
            <IconCaretDown className="text-[8px]  ml-1" />
            {showDropdown && (
                <FloatingPortal>
                    <DropdownMenu
                        className="shadow-menu rounded-lg py-2 z-[99]"
                        ref={refs.setFloating}
                        style={floatingStyles}
                        onClick={handleClickDropdown}
                    >
                        {[0, 3, 7, -1].map((e, index) => (
                            <div
                                key={e}
                                className={classNames("menu-item")}
                                onClick={() => handleSelect(e)}
                            >
                                {e == 0
                                    ? "Hôm nay"
                                    : e === -1
                                    ? "Chọn ngày"
                                    : `Trong ${e} ngày gần đây`}
                            </div>
                        ))}
                    </DropdownMenu>
                </FloatingPortal>
            )}
            <CalendarDialog
                date={date}
                isOpen={openCalendar}
                setIsOpen={setOpenCalendar}
                onSelect={handleSelectDate}
            />
        </div>
    )
}

const DropdownMenu = styled.div`
    min-width: 140px;
    padding: 4px;
    background-color: #fff;
    div {
        font-size: 14px;
    }

    .menu-item {
        padding: 0 12px;
        display: flex;
        user-select: none;
        align-items: center;
        height: 32px;
        border-radius: 4px;
        &:hover {
            background-color: #f5f5f5;
            cursor: pointer;
        }
    }
`
