import { BookingStatus } from "services/constants"

export const BookingStatusBadge = ({ status }: { status: number }) => {
    const mapper = {
        [BookingStatus.ASSIGNED]: "Chưa chăm sóc",
        [BookingStatus.BOOKED]: "Đã đặt",
        [BookingStatus.SERVING]: "Đang chăm sóc",
        [BookingStatus.CANCELLED]: "Đã hủy",
    }
    const colorMapper = {
        [BookingStatus.ASSIGNED]: "#7489A4",
        [BookingStatus.BOOKED]: "#2379EA",
        [BookingStatus.SERVING]: "#FF9800",
        [BookingStatus.CANCELLED]: "#D12180",
    }
    const label = mapper[status]
    const color = colorMapper[status]
    return (
        <div
            style={{
                backgroundColor: color,
            }}
            className="flex items-center whitespace-nowrap text-white font-medium px-1 text-13 h-6 justify-center rounded"
        >
            {label}
        </div>
    )
}
