import { blackA } from "@radix-ui/colors"
import * as Dialog from "@radix-ui/react-dialog"
import classNames from "classnames"
import IconChevronLeft from "common/icons/IconChevronLeft"
import IconChevronRight from "common/icons/IconChevronRight"
import { ReactNode } from "react"
import { NumericFormat } from "react-number-format"
import { ClearIndicatorProps } from "react-select"
import TextareaAutosize from "react-textarea-autosize"
import styled, { keyframes, css } from "styled-components"

const overlayShow = keyframes`
 0% { opacity: 0;  }
 100% { opacity: 1;  }
`
const contentShow = keyframes`
 0% { opacity: 0; transform: translate(-50%, -48%) scale(.96); }
 100% { opacity: 1; transform: translate(-50%, -50%) scale(1); }
`
export const IconSelect = () => (
    <span
        role="img"
        aria-label="Dropdown emoji"
        className="icon-chevron-down text-placeholder text-xs mr-4 "
    ></span>
)


export const ChevronLeftButton = (
    props: React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    >
) => {
    const { className, ...others } = props
    return (
        <div
            className={classNames(
                "h-7 w-7 rounded bg-white text-[#a7a7a7] hover:text-link flex items-center justify-center",
                className
            )}
            {...others}
        >
            <IconChevronLeft className="text-xl"/>
        </div>
    )
}
export const ChevronRightButton = (
    props: React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    >
) => {
    const { className, ...others } = props
    return (
        <div
            className={classNames(
                "h-7 w-7 rounded bg-white text-[#a7a7a7] hover:text-link  flex items-center justify-center",
                className
            )}
            {...others}
        >
            <IconChevronRight className="text-xl" />
        </div>
    )
}

export const Layout = styled.div`
    display: flex;
    min-height: 0;
    transform: translateZ(0);
    
`

export const Page = styled.div`
    height: 100vh;
`

export const DialogOverlay = styled(Dialog.Overlay)`
    background-color: ${blackA.blackA9};
    position: fixed;
    inset: 0;
    animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
`

export const DialogContent = styled(Dialog.Content)<{ top: string }>`
    background-color: white;
    border-radius: 6px;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
        hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: ${(props) => props.top || "30%"};
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    max-width: 450px;
    max-height: 85vh;
    padding: 25px;
    animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
    &:focus {
        outline: none;
    }
`

export const Input = styled.input<{ error?: boolean }>`
    border: 1px solid var(--input-border);
    box-sizing: border-box;
    border-radius: var(--input-border-radius);
    background-color: var(--input-bg-color);
    text-indent: 12px;
    font-size: 14px;
    height: 36px;
    width: 100%;
    outline: none;
    &:hover {
        border-color: var(--input-border);
    }
    &:focus {
        box-shadow: 0 0 4px 1px rgb(40 153 236 / 30%), 0 0 0 1px #2872bb;
        border: 1px solid var(--input-border-focus);
        outline: none;
    }
    ${(props) =>
        props.error &&
        css`
            background-color: #fff6fb;
            border-color: #f85d96;
            &:hover {
                border-color: #f85d96;
            }
            &:focus {
                border-color: #f85d96;
                box-shadow: 0 0 2px 1px #ed6a9c, 0 0 0 1px #f85d96;
            }
        `}

    &::placeholder {
        color: var(--text-placeholder);
        font-size: 1em;
    }
    transition: border 0.16s ease;
`

export const Card = styled.div`
    border-radius: 8px;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.04), 0 2px 5px 0 rgba(0, 0, 0, 0.06),
        0 0 0 1px rgba(0, 0, 0, 0.03) !important;
    background-color: white;
`

export const CardForm = styled.div`
    border-radius: 8px;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.04), 0 2px 5px 0 rgba(0, 0, 0, 0.06),
        0 0 0 1px rgba(0, 0, 0, 0.03) !important;
    background-color: white;
    padding: 20px 40px 16px 40px;
    margin-top: 12px;
    font-size: 13px;
`

export const FormHeader = styled.div`
    font-size: 15px;
    margin-left: -20px;
    font-weight: 600;
    margin-bottom: 24px;
`

export const FormGroup = (props: {
    className?: string
    label: string
    children?: ReactNode
}) => {
    return (
        <div className={classNames("flex items-center pb-3", props.className)}>
            <div className="flex-1">{props.label}</div>
            <div className="flex-[3]">{props.children}</div>
        </div>
    )
}

const BaseButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    min-width: 80px;
    padding: 4px 12px;
    box-sizing: border-box;
    border-radius: 6px;
    font-size: 13px;
    text-align: center;
    cursor: default;
    transition: all 0.3s;
`

export const TextArea = styled(TextareaAutosize)`
    width: 100%;
    border: 1px solid var(--input-border);
    border-radius: var(--input-border-radius);
    background-color: var(--input-bg-color);
    resize: none;
    outline: none;
    font-size: 14px;
    padding: 8px 12px;
    &:hover {
        border-color: var(--input-border);
    }
    &:focus {
        box-shadow: 0 0 4px 1px rgb(40 153 236 / 30%), 0 0 0 1px #2872bb;
        border: 1px solid var(--input-border-focus);
        outline: none;
    }
    transition: all 0.3s;
`
export const Button = styled(BaseButton)<{ size?: "lg" }>`
    &.pointer-events-none {
        pointer-events: none;
    }
    border: none;
    background-color: var(--brand);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    color: #fff;
    &:disabled {
        opacity: 0.4;
        pointer-events: none;
    }
    height: ${(props) => (props.size == "lg" ? "38px" : "32px")};
    font-size: ${(props) => (props.size === "lg" ? "14px" : "1em")};
    &:hover {
        background-color: var(--brand-hover);
        color: #fff;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgb(164, 202, 254, 0.45);
        position: relative;
        z-index: 1;
    }
    transition: all 0.3s;
`

export const NumericInput = styled(NumericFormat)`
    border: 1px solid var(--input-border);
    box-sizing: border-box;
    border-radius: var(--input-border-radius);
    background-color: var(--input-bg-color);
    text-indent: 12px;
    font-size: 1em;
    height: 36px;
    width: 100%;
    outline: none;
    &:hover {
        border-color: var(--input-border);
    }
    &:focus {
        box-shadow: 0 0 4px 1px rgb(40 153 236 / 30%), 0 0 0 1px #2872bb;
        border: 1px solid var(--input-border-focus);
        outline: none;
    }

    &::placeholder {
        color: var(--text-placeholder);
    }
    transition: all 0.3s;
`

export const DangerButton = styled(BaseButton)`
    &.pointer-events-none {
        pointer-events: none;
    }
    border: none;
    background-color: var(--text-danger);
    color: #fff;
    &:disabled {
        opacity: 0.4;
        pointer-events: none;
    }
    &:hover {
        background-color: var(--text-danger-hover);
        color: #fff;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgb(193, 10, 71, 0.45);
        position: relative;
        z-index: 1;
    }
`
export const OutlineButton = styled(BaseButton)`
    color: var(--text-button);
    border-color: var(--border-strong);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    &.pointer-events-none {
        pointer-events: none;
    }
    background-color: #fff;
    &:disabled {
        opacity: 0.4;
    }
    &:hover {
        color: var(--text-button-hover);
        background-color: var(--bg);
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgb(164, 202, 254, 0.45);
        position: relative;
        z-index: 1;
    }
`

export const TextButton = styled(BaseButton)`
    color: var(--link);
    border-color: var(--border-strong);
    background-color: #fff;

    &.pointer-events-none {
        pointer-events: none;
    }
    border: none;
    &:hover {
        background-color: transparent !important;
        color: #005967 !important;
    }
    &:disabled {
        opacity: 0.4;
    }
    cursor: default;
    &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgb(164, 202, 254, 0.45);
        color: var(--text-button-hover);
        border-color: var(--border-strong);
        background-color: #fff;
    }
`

export const DropdownMenu = styled.div<{ disabled?: boolean }>`
    display: flex;
    cursor: default;
    align-items: center;
    min-width: 250px;
    padding: 12px 16px;
    color: var(--text-primary);
    font-size: 14px;
    border-radius: 6px;
    overflow: hidden;
    &:hover {
        background-color: rgb(246, 247, 247);
    }
    opacity: ${(props) => (props.disabled ? 0.3 : 1)};
    pointer-events: ${(props) => (props.disabled ? "none" : "all")};
`

