import { getLunarDate, stripAccents } from "../../services/utils"

declare global {
    interface Array<T> {
        max(): number
    }
    interface Array<T> {
        unique(): Array<T>
    }

    // interface StringConstructor {
    //     containsIgnoreCase(s: string): boolean
    // }
    interface String {
        trimSlashes(this: string): string
    }
    interface Date {
        toYMDNumber(this: Date): number
    }
    interface Date {
        getDateName(this: Date): string
    }
    interface Date {
        getLunarDate(showYear?: boolean): string
    }

    interface Date {
        formatDMY(separator?: string): string
    }
    interface Date {
        formatYMD(separator?: string): string
    }
    interface Date {
        formatHHmm(this: Date): string
    }
    interface Number {
        formatCurrency(): string
    }
    interface Number {
        formatDMYHM(): string
    }
    interface Number {
        padZero(): string
    }
}
if (!Array.prototype.max) {
    Array.prototype.max = function () {
        return Math.max.apply(null, this)
    }
}
if (!Array.prototype.unique) {
    Array.prototype.unique = function <T>() {
        var result: T[] = this.filter(
            (item, i, ar) => ar.indexOf(item) === i
        ) as T[]
        return result
    }
}

// if (!String.prototype.stripNonNumbers) {
//     String.stripNonNumbers = function () {
//         let d = String(this)
//         return d.replace(/\D/g, "") || ""
//     }
// }

/**
 *   DATE PROTOTYPES
 */

if (!Date.prototype.getDateName) {
    Date.prototype.getDateName = function () {
        var day = this.getDay()
        const dayNames = [
            "Chủ nhật",
            "Thứ 2",
            "Thứ 3",
            "Thứ 4",
            "Thứ 5",
            "Thứ 6",
            "Thứ 7",
        ]
        return dayNames[day]
    }
}

if (!Date.prototype.toYMDNumber) {
    Date.prototype.toYMDNumber = function () {
        const year = this.getFullYear().toString().substring(2, 4)
        const month = (this.getMonth() + 1).padZero()
        const day = this.getDate().padZero()
        return +`${year}${month}${day}`
    }
}
if (!Date.prototype.getLunarDate) {
    Date.prototype.getLunarDate = function (showYear?: boolean) {
        const { month, day, year } = getLunarDate(this)
        if (showYear) {
            return `${day}/${month}/${year}`
        }
        return `${day}/${month}`
    }
}
if (!Date.prototype.formatDMY) {
    Date.prototype.formatDMY = function (separator?: string) {
        separator = separator || "-"
        var day = this.getDate().padZero()
        var year = this.getFullYear()
        var month = (this.getMonth() + 1).padZero()
        return `${day}${separator}${month}${separator}${year}`
    }
}
if (!Date.prototype.formatYMD) {
    Date.prototype.formatYMD = function (separator?: string) {
        separator = separator || "-"
        var day = this.getDate().padZero()
        var year = this.getFullYear()
        var month = (this.getMonth() + 1).padZero()
        return `${year}${separator}${month}${separator}${day}`
    }
}
if (!Date.prototype.formatHHmm) {
    Date.prototype.formatHHmm = function () {
        var hour = this.getHours().padZero()
        var min = this.getMinutes().padZero()
        return `${hour}:${min}`
    }
}
if (!Number.prototype.formatCurrency) {
    Number.prototype.formatCurrency = function () {
        return this.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
}
if (!Number.prototype.formatDMYHM) {
    Number.prototype.formatDMYHM = function () {
        let date = new Date(Number(this))
        return `${date.formatDMY()} ${date.formatHHmm()}`
    }
}
if (!Number.prototype.padZero) {
    Number.prototype.padZero = function () {
        return this.toString().padStart(2, "0")
    }
}

export {}
