import classNames from "classnames"
import { useNavigate } from "react-router"

export const AppLogo = ({ className }: { className?: string }) => {
    const navigate = useNavigate()
    return (
        <div className={classNames(className)}
            onClick={() => navigate('/')}
        >
            <div className="font-semibold text-xl flex items-center">
                <span className="text-white">Sale</span>&nbsp;
                <span className="text-[#F30B6D]">Booking</span>
            </div>
            <div className=" text-white text-[10px]">Manager</div>
        </div>
    )
}
