import classNames from "classnames"
import { CLOUD_FRONT_URL } from "services/constants"
import { rootStore } from "stores/rootStore"
import { useSnapshot } from "valtio"

type Props = {
    className?: string
}

export const UserAvatar = ({className}: Props) => {
    const { currentUser } = useSnapshot(rootStore)
    return (
        <img
            className={classNames("rounded-full w-8 h-8 object-cover", className)}
            src={
                currentUser?.photoId
                    ? `${CLOUD_FRONT_URL}/${currentUser.photoId}`
                    : "/images/avatar-default.webp"
            }
        />
    )
}
