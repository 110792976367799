import { UserItem } from "models/user"
import { proxy } from "valtio"

export const rootStore = proxy({
    currentUser: <UserItem>{
        id: 0,
        code: "",
        roleId: 0,
        displayName: "",
        userName: "",
        photoId: "",
        phoneNumber: "",
        buildNumber: "",
        buildVersion: "",
        token: "",
        verificationCode: "",
        webDevice: "",
        webToken: "",
        deviceId: "",
        deviceName: "",
        permissions: [],
        active: false,
        ts: 0,
    },
    permissions: <number[]>[],
    fcmToken: "",
})
