import { useEffect, useState } from "react"
import { styled } from "styled-components"
import { Input } from "common/ui/styledComponents"
import IconSearch from "common/icons/IconSearch"
import IconClose from "common/icons/IconClose"
import classNames from "classnames"

type Props = {
    value: string
    placeholder?: string
    className?: string
    size?: "sm"
    onChange?: (s: string) => void
    rounded?: boolean
    hideSearchIcon?: boolean
}
const SearchBox = ({
    value,
    onChange,
    size,
    placeholder,
    className,
    rounded,
    hideSearchIcon,
}: Props) => {
    const [searchText, setSearchText] = useState("")

    useEffect(() => {
        setSearchText(value)
    }, [value])

    const handleMouseDown = (e: React.MouseEvent) => {
        e.stopPropagation()
        e.preventDefault()
        setSearchText("")
        onChange && onChange("")
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        var value = e.target.value
        setSearchText(e.target.value)
        onChange && onChange(value)
    }
    return (
        <SearchBoxWrap
            className={classNames(
                "relative",

                {
                    "size-sm": size == "sm",
                    "is-rounded": rounded,
                    "hide-search": hideSearchIcon,
                },
                className
            )}
        >
            {!hideSearchIcon && <IconSearch className="icon" />}
            <Input
                placeholder={placeholder}
                value={searchText}
                onChange={handleChange}
            />
            {!!searchText && (
                <CloseIcon
                    onMouseDown={handleMouseDown}
                    className="flex items-center justify-center w-4 h-4 rounded-full"
                >
                    <IconClose className="close " />
                </CloseIcon>
            )}
        </SearchBoxWrap>
    )
}

export default SearchBox

const SearchBoxWrap = styled.div`
    border-radius: 4px;
    input {
        border-radius: 4px;
        text-indent: 36px;
        padding-right: 16px;
        background-color: #fff;
    }
    &.hide-search input {
        text-indent: 12px;
    }
    .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 12px;
        color: #aaa;
    }
    &.size-sm {
        input {
            height: 32px;
            text-indent: 30px;
        }
        .icon {
            left: 9px;
        }
    }
    &.is-rounded {
        border-radius: 99px;
        input {
            border-radius: 99px;
        }
    }
`
const CloseIcon = styled.div`
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    .close {
        color: #fff;
        font-size: 12px;
        cursor: pointer;
    }
    &:hover {
        background-color: rgba(0, 0, 0, 0.6);
    }
    transition: all 0.3s;
`
