import ErrorPage from "ErrorPage"
import Layout from "Layout.tsx"
import "common/ui/prototypes"
import Login from "modules/auth/Login"
import Settings from "modules/settings/Settings"
import ReactDOM from "react-dom/client"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { initFirebase } from "services/firebase"
import * as timeago from "timeago.js"
import vi from "timeago.js/lib/lang/vi"

import { Bookings } from "modules/booking/Bookings"
import NoInternetPage from "modules/error_page/NoInternetPage"
import { Report } from "modules/report/Report"
import { ReportLayout } from "modules/report/ReportLayout"
import { SettingsLayout } from "modules/settings/SettingsLayout"
import UserTable from "modules/settings/User/UserTable"
import { AppRoutes } from "services/constants"
import "./index.scss"
import { SaleTable } from "modules/sale/SaleTable"
import { Sales } from "modules/sale/Sales"
import { SaleValueReport } from "modules/report/components/SaleValueReport"

timeago.register("vi", vi)

//phải để lên trước hàm createBrowserRouter nếu ko sẽ lỗi
initFirebase()

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: ``,
                element: <Bookings />,
            },
            {
                path: `/booking`,
                element: <Bookings />,
            },
            {
                path: `/sales`,
                element: <Sales />,
            },
            {
                path: AppRoutes.REPORT,
                element: <Report />,
                children: [
                    {
                        path: ``,
                        index: true,
                        element: <ReportLayout />,
                    },
                    {
                        path: `${AppRoutes.REPORT}/sales-value`,
                        element: <SaleValueReport />,
                    },
                ],
            },

            {
                path: `${AppRoutes.SETTINGS}`,
                element: <Settings />,
                children: [
                    {
                        path: ``,
                        index: true,
                        element: <SettingsLayout />,
                    },

                    {
                        path: `${AppRoutes.SETTINGS}/users`,
                        element: <UserTable />,
                    },
                ],
            },
        ],
    },
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/no-connection",
        element: <NoInternetPage />,
    },
])

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <RouterProvider router={router} />
)
