import { IconBook } from "common/icons/IconBook"
import IconChartBox from "common/icons/IconChartBox"
import IconDate from "common/icons/IconDate"
import IconSetting from "common/icons/IconSetting"
import { NavLink } from "react-router-dom"
import { AppRoutes, Role } from "services/constants"
import { rootStore } from "stores/rootStore"
import styled from "styled-components"
import { useSnapshot } from "valtio"
import { AppLogo } from "./AppLogo"
import { UserAvatar } from "./UserAvatar"
import IconSignout from "common/icons/IconSignout"

const Sidebar = () => {
    const { currentUser } = useSnapshot(rootStore)
    return (
        <SidebarContainer className="sidebar-inner">
            <AppLogo className="ml-8 mt-5 mt-r mb-8 cursor-pointer" />

            <div className="mt-4 mb-10 flex items-center ml-4 text-white text-xs font-medium">
                <UserAvatar className="mr-2" />
                <div className="text-sm">
                    <div>{currentUser?.displayName}</div>
                    <div className="text-xs mt-1">
                        {currentUser?.roleId == Role.MANAGER
                            ? "Quản lý"
                            : "Sale"}
                    </div>
                </div>
            </div>
            <StyledNavLink
                to={`/booking`}
                className="mx-5 my-5 text-sm font-medium"
            >
                <div className="menu flex items-center ">
                    <IconDate className="mr-4" />
                    Booking
                </div>
            </StyledNavLink>

            <StyledNavLink
                to={`/sales`}
                className="mx-5 my-5 text-sm font-medium"
            >
                <div className="menu flex items-center ">
                    <IconBook className="mr-4" />
                    Sales
                </div>
            </StyledNavLink>
            <StyledNavLink
                to={`${AppRoutes.REPORT}`}
                className="mx-5 my-5 text-sm font-medium"
            >
                <div className="menu flex items-center ">
                    <IconChartBox className="mr-3 text-lg" />
                    Báo cáo
                </div>
            </StyledNavLink>
            <StyledNavLink
                to={`${AppRoutes.SETTINGS}`}
                className="mx-5 my-5 text-sm font-medium"
            >
                <div className="menu flex items-center ">
                    <IconSetting className="mr-4" />
                    Cài đặt
                </div>
            </StyledNavLink>
            <StyledNavLink
                to={`/login`}
                className="mx-5 my-5 text-sm font-medium"
            >
                <div className="menu flex items-center ">
                    <IconSignout className="mr-4" />
                    Thoát
                </div>
            </StyledNavLink>
        </SidebarContainer>
    )
}

export default Sidebar

const SidebarContainer = styled.div`
    height: 100vh;
    min-height: 0;
    overflow-y: auto;
    width: 200px;
    background-color: #131734;
    .menu {
        transition: background-color 0.25s ease-out;
        &.active {
            background-color: var(--dark-blue);
        }
    }
    .menu-inner {
        height: 40px;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        .icon {
            font-size: 18px;
        }
        .arrow {
            font-size: 10px;
        }
        div.menu-title {
            margin-left: 16px;
            font-size: 14px;
            font-weight: 500;
            flex: 1;
            user-select: none;
        }
        &:hover {
            background-color: var(--light-blue-hover);
            color: #fff;
        }
    }
`
const StyledNavLink = styled(NavLink)`
    color: white;
    display: flex;
    cursor: pointer;
    &.active {
        color: pink;
    }
`
