import Dialog from "common/ui/dialog/Dialog"
import DatePickerInner from "./DatePickerInner"
import { useEffect, useState } from "react"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    onSelect: (d: Date) => void
    date: Date
}

export const CalendarDialog = ({
    isOpen,
    setIsOpen,
    date,
    onSelect,
}: Props) => {
    const [selectedDate, setSelectedDate] = useState(new Date())

    const handleViewMonthChange = () => {}
    const handleSelect = (d: Date) => {
        setSelectedDate(d)
        onSelect(d)
    }

    useEffect(() => {
        setSelectedDate(date)
    }, [date])
    return (
        <Dialog
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            closeOnClickBackdrop={true}
        >
            <div className="m-auto scaleIn shadow-menu min-h-[100px] rounded-lg  w-[400px] mt-[5vh] bg-white">
                <div className="p-5">
                    <DatePickerInner
                        selectedDate={selectedDate}
                        onSelect={handleSelect}
                        onViewMonthChange={handleViewMonthChange}
                    />
                </div>
            </div>
        </Dialog>
    )
}
