import { initializeApp } from "firebase/app"
import "firebase/database"
import {
    getMessaging,
    getToken,
    onMessage,
    isSupported,
} from "firebase/messaging"
import "firebase/auth"
import { rootStore } from "stores/rootStore"

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBNFxrD8H6pLi57q6eOGx-7kQBhWaP5p1I",
    authDomain: "gonow-b5fd1.firebaseapp.com",
    projectId: "gonow-b5fd1",
    storageBucket: "gonow-b5fd1.appspot.com",
    messagingSenderId: "670491314547",
    appId: "1:670491314547:web:f0877ce580e9419856dd06",
    measurementId: "G-6G5M6R8Q9S",
    databaseURL: "https://gonow-b5fd1-default-rtdb.asia-southeast1.firebasedatabase.app"
}

export const VAPID_KEY =
    "BEQiQo-4EMBPdBZ4UeUDweULq07MgVS891ukwkV4n2ws8bYFAm_UE9rSb7oaVnIImIXRE-TTWdQmyyiTi130bIU"

export const initFirebase = () => {
    console.log("firebase initialized!")
    initializeApp(firebaseConfig)
}
export const app = initializeApp(firebaseConfig)
export const messaging = getMessaging(app)

export const requestPermission = () => {
    console.log("Requesting User Permission......")
    Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
            console.log("Notification User Permission Granted.")
            return getToken(messaging, {
                vapidKey: VAPID_KEY,
            })
                .then((currentToken) => {
                    rootStore.fcmToken = currentToken || ""
                    if (currentToken) {
                        console.log("Client Token: ", currentToken)
                    } else {
                        console.log(
                            "Failed to generate the app registration token."
                        )
                    }
                })
                .catch((err) => {
                    console.log(
                        "An error occurred when requesting to receive the token.",
                        err
                    )
                })
        } else {
            console.log("User Permission Denied.")
        }
    })
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            console.log(messaging)
            resolve(payload)
        })
    })

requestPermission()
