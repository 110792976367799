import { Booking } from "common/types"
import API from "./api"
import { CommonListResponse, CommonResponse } from "models/response"

export const BookingService = {
    async getBookings({ from, to }: { from: number; to: number }) {
        var res = await API.get<Booking[]>(`/bookings?from=${from}&to=${to}`)
        return res.data
    },
    async getLatest(ts: number) {
        var res = await API.get<Booking[]>(`/bookings?ts=${ts}`)
        return res.data
    },

    async serveBooking(bookingId: string) {
        var res = await API.put<CommonResponse>(`/bookings/serve`, {
            bookingId,
        })
        return res.data
    },
    async setBooked(bookingId: string) {
        var res = await API.put<CommonResponse>(`/bookings/book`, {
            bookingId,
        })
        return res.data
    },
    // async add(
    //     item: Pick<
    //         UserItem,
    //         | "userName"
    //         | "displayName"
    //         | "password"
    //         | "roleId"
    //         | "phoneNumber"
    //         | "code"
    //     >
    // ) {
    //     var res = await API.post<CommonResponse>(`/users`, item)
    //     return res.data
    // },
    // async update(item: Pick<Material, "id" | "name" | "code">) {
    //     var res = await API.put<CommonResponse>(`/material-groups`, {
    //         id: item.id,
    //         code: item.code,
    //         name: item.name,
    //     })
    //     return res.data
    // },
    // async archieve(id: number) {
    //     var res = await API.delete<CommonResponse>(`/material-groups/${id}`)
    //     return res.data
    // },
}
