import { ErrorMessage } from "common/components/ErrorMesssage"
import { Button } from "common/components/button/Button"
import { RegisterForm } from "common/types"
import Checkbox from "common/ui/Checkbox"
import DateInput from "common/ui/datepicker/DateInput"
import Dialog from "common/ui/dialog/Dialog"
import DialogTitle from "common/ui/dialog/DialogTitle"
import { Input, NumericInput } from "common/ui/styledComponents"
import { differenceInCalendarDays } from "date-fns"
import { UserItem } from "models/user"
import { useEffect, useState } from "react"
import { NumberFormatValues } from "react-number-format"
import { Role } from "services/constants"
import { showSuccess } from "services/notificationService"
import { RegisterService } from "services/registerService"
import { UserAction } from "stores/actionStores/userAction"
import DateUtils from "utils/DateUtils"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}
export const RegisterGuest = ({ isOpen, setIsOpen }: Props) => {
    // const { checklistNames } = useSnapshot(todoStore)
    const [name, setName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [email, setEmail] = useState("")
    const [checkinDate, setCheckinDate] = useState(new Date())
    const [checkoutDate, setCheckoutDate] = useState(new Date())
    const [loading, setLoading] = useState(false)
    const [adults, setAdults] = useState(0)
    const [children, setChildren] = useState(0)
    const [price, setPrice] = useState(0)
    const [errorMessage, setErrorMessage] = useState("")
    const [paid, setPaid] = useState(false)

    useEffect(() => {
        if (differenceInCalendarDays(checkoutDate, checkinDate) < 0) {
            setCheckoutDate(new Date(checkinDate.getTime()))
        }
    }, [checkinDate, checkoutDate])

    useEffect(() => {
        if (!isOpen) {
            setName("")
            setEmail("")
            setAdults(0)
            setChildren(0)
            setPrice(0)
            setPaid(false)
            setPhoneNumber("")
            setName("")
        }
    }, [isOpen])

    const handleSave = async () => {
        const payload: RegisterForm = {
            adults,
            checkinDate: checkinDate.formatYMD(),
            checkoutDate: checkoutDate.formatYMD(),
            children,
            email,
            name,
            paid,
            phoneNumber,
            price,
            apiKey: "-_uBPPPL_4jm9tgSUAUnN",
        }
        const response = await RegisterService.register(payload)
        if (!response.error) {
            setIsOpen(false)
            showSuccess("Đăng ký thành công!")
        } else {
            setErrorMessage("Đăng ký không thành công!")
        }
        // const isValid = validateForm()
        // if (!isValid) {
        //     return
        // }
        // setLoading(true)
        // let payload: Pick<
        //     UserItem,
        //     | "userName"
        //     | "displayName"
        //     | "password"
        //     | "roleId"
        //     | "phoneNumber"
        //     | "code"
        // > = {
        //     userName,
        //     displayName,
        //     roleId,
        //     password,
        //     code,
        //     phoneNumber: "",
        // }
        // setLoading(true)
        // let response = await UserAction.add(payload)
        // setLoading(false)
        // if (!response.error) {
        //     setIsOpen(false)
        // } else {
        //     setErrorMessage(response.message || "")
        // }

        setLoading(false)
    }
    const handleChangeRole = (ev: React.FormEvent<HTMLFormElement>) => {
        console.log(ev)
    }
    return (
        <Dialog
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            closeOnClickBackdrop={false}
        >
            <div className="m-auto scaleIn shadow-menu min-h-[100px] rounded-lg  w-[500px] mt-[5vh] bg-white">
                <DialogTitle>Form đăng ký</DialogTitle>
                <div className=" pt-4 pb-4 px-12 relative">
                    <div className="font-bold mb-2 uppercase text-xs">
                        Tên khách hàng
                    </div>
                    <Input
                        className="text-md bg-white"
                        placeholder={"Nhập tên khách hàng"}
                        value={name}
                        autoFocus={true}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <div className="flex itesc gap-5 mt-5">
                        <div className="flex-1">
                            <div className="font-bold mb-2 uppercase text-xs">
                                Số điện thoại
                            </div>

                            <Input
                                className="text-md bg-white"
                                placeholder={"Nhập số điện thoại"}
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                        </div>
                        <div className="flex-1">
                            <div className="font-bold mb-2 uppercase text-xs">
                                Email
                            </div>
                            <Input
                                className="text-md bg-white"
                                placeholder={"Nhập email"}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="flex items-center mt-5 gap-5">
                        <div className="flex-1">
                            <div className="font-bold mb-2 uppercase text-xs">
                                Ngày checkin
                            </div>
                            <DateInput
                                selectedDate={checkinDate}
                                onSelect={setCheckinDate}
                            />
                        </div>
                        <div className="flex-1">
                            <div className="font-bold mb-2 uppercase text-xs">
                                Ngày checkout
                            </div>
                            <DateInput
                                selectedDate={checkoutDate}
                                onSelect={setCheckoutDate}
                            />
                        </div>
                    </div>
                    <div className="flex items-center mt-5 gap-5">
                        <div className="flex-1">
                            <div className="font-bold mb-2 uppercase text-xs">
                                Người lớn
                            </div>
                            <NumericInput
                                placeholder="0"
                                value={adults || ""}
                                allowLeadingZeros
                                thousandSeparator=","
                                onValueChange={(values: NumberFormatValues) => {
                                    const _value = values.floatValue
                                    setAdults(_value || 0)
                                }}
                            />
                        </div>
                        <div className="flex-1">
                            <div className="font-bold mb-2 uppercase text-xs">
                                Trẻ em
                            </div>
                            <NumericInput
                                placeholder="0"
                                value={children || ""}
                                allowLeadingZeros
                                thousandSeparator=","
                                onValueChange={(values: NumberFormatValues) => {
                                    const _value = values.floatValue
                                    setChildren(_value || 0)
                                }}
                            />
                        </div>
                    </div>
                    <div className="font-bold mb-2 uppercase text-xs mt-5">
                        Giá trị đơn hàng
                    </div>
                    <NumericInput
                        placeholder="0"
                        value={price || ""}
                        allowLeadingZeros
                        thousandSeparator=","
                        onValueChange={(values: NumberFormatValues) => {
                            const _value = values.floatValue
                            setPrice(_value || 0)
                        }}
                    />
                    <div className="font-bold mb-2 uppercase flex items-center text-xs mt-5">
                        Đã thanh toán &nbsp;
                        <Checkbox value={paid} onChange={setPaid} />
                    </div>

                    {errorMessage && <ErrorMessage content={errorMessage} />}

                    <div className="flex items-center gap-5 justify-end mt-8">
                        <Button
                            onClick={handleSave}
                            loading={loading}
                            disabled={
                                !phoneNumber || !phoneNumber.trim() || !name
                            }
                            className="w-24 "
                        >
                            Đăng ký
                        </Button>
                        <Button
                            outline
                            className="border-none shadow-none"
                            onClick={() => setIsOpen(false)}
                        >
                            Hủy
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
