import Sidebar from "common/components/Sidebar"
import { Layout } from "common/ui/styledComponents"
import { Outlet } from "react-router-dom"

const Settings = () => {
    return (
        <Layout>
            <Sidebar />
            <Outlet />
        </Layout>
    )
}

export default Settings
