import { ReactComponent as Loader } from "assets/svgs/loader.svg"
import { forwardRef } from "react"
import styled from "styled-components"

type ButtonProps = React.ComponentPropsWithoutRef<"button"> & {
    primary?: boolean
    danger?: boolean
    disabled?: boolean
    loading?: boolean
}
export type Ref = HTMLButtonElement
export const RoundedButton = forwardRef<Ref, ButtonProps>(
    ({ primary, children, disabled, loading, ...others }: ButtonProps, ref) => {
        return (
            <StyledButton
                primary={primary}
                disabled={disabled}
                ref={ref}
                {...others}
            >
                {loading ? <Loader className="spinner" /> : children}
            </StyledButton>
        )
    }
)

const StyledButton = styled.button<{
    primary?: boolean
    danger?: boolean
}>`
    &.pointer-events-none {
        pointer-events: none;
    }
    flex-shrink: 0;
    user-select: none;
    border: none;
    border-radius: 99px;
    text-align: center;
    display: flex;
    align-items: center;
    background-color: ${(props) =>
        props.primary ? "#5D5B5E" : props.danger ? "#EB1F1F" : "#ECECEC"};
    &:disabled {
        opacity: 0.2;
        pointer-events: none;
    }
    height: 36px;
    color: ${(props) => (props.primary || props.danger ? "#fff" : "#000")};
    font-size: 14px;
    font-weight: 500;

    padding: 0 16px;

    /* &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgb(164,202,254, 0.45);
        position: relative;
        z-index: 1;
    } */
    transition: all 0.3s;
`
