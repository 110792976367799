import { Booking, SaleValue } from "common/types"
import API from "./api"
import { CommonListResponse, CommonResponse } from "models/response"

export const ReportService = {
    async getSaleValueReport({ from, to }: { from: number; to: number }) {
        var res = await API.get<SaleValue[]>(
            `/report/sale-value?from=${from}&to=${to}`
        )
        return res.data
    },
}
