import Dialog from "common/ui/dialog/Dialog"

export const LoadingDialog = ({
    loading,
    setLoading,
}: {
    loading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    return (
        <Dialog
            isOpen={loading}
            setIsOpen={setLoading}
            closeOnClickBackdrop={false}
        >
            <div className="m-auto scaleIn shadow-menu h-[72px] rounded-lg text-sm  w-[220px] mt-[40vh] bg-[rgba(0,0,0,0.66)] flex items-center gap-4 text-white  justify-center">
                <img className="w-5" src="/images/loading.gif" />
                Đang cập nhật
            </div>
        </Dialog>
    )
}
