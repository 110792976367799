import styled from "styled-components"

export const OrangeBall = styled.div`
    background-image: linear-gradient(
        165deg,
        hsl(40deg 100% 50%) 0%,
        hsl(38deg 100% 50%) 21%,
        hsl(37deg 99% 50%) 30%,
        hsl(36deg 99% 50%) 39%,
        hsl(35deg 98% 51%) 46%,
        hsl(34deg 98% 51%) 54%,
        hsl(33deg 97% 51%) 61%,
        hsl(31deg 96% 51%) 69%,
        hsl(30deg 95% 52%) 79%,
        hsl(29deg 94% 52%) 100%
    );
`
export const PurpleBall = styled.div`
    background: linear-gradient(160.56deg, #bf9afc 0%, #4f2be1 100%);
`
export const GreenBall = styled.div`
    background: linear-gradient(160.56deg, #b2e228 0%, #52d117 100%);
`
export const RedBall = styled.div`
    background-image: linear-gradient(
        15deg,
        hsl(22deg 85% 62%) 0%,
        hsl(19deg 88% 62%) 9%,
        hsl(17deg 90% 62%) 19%,
        hsl(14deg 92% 62%) 29%,
        hsl(11deg 94% 62%) 39%,
        hsl(7deg 95% 62%) 50%,
        hsl(3deg 97% 63%) 61%,
        hsl(358deg 98% 62%) 73%,
        hsl(353deg 99% 59%) 86%,
        hsl(346deg 100% 54%) 100%
    );
`

export const YellowBall = styled.div`
    background: #f5ec0f;
    background: linear-gradient(180deg, #F5EC0F 0%, #D6AE1F 100%);
`
