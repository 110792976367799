import Sidebar from "common/components/Sidebar"
import { RoundedButton } from "common/components/button/RoundedButton"
import { Layout, Page } from "common/ui/styledComponents"
import { useEffect, useState } from "react"
import { bookingStore } from "stores/bookingStore"
import { useSnapshot } from "valtio"
import { SaleTable } from "./SaleTable"
import { getDatabase, off, onValue, ref } from "firebase/database"
import { AppConfig } from "services/appConfig"
import { BookingAction } from "stores/actionStores/bookingAction"
import { SaleAction } from "stores/actionStores/saleAction"

export const Sales = () => {
    useEffect(() => {
        const db = getDatabase()
        const saleBookingRef = ref(db, `/companies/gonow/saleBooking`)
        onValue(saleBookingRef, (snapshot) => {
            const saleId = snapshot.val()
            SaleAction.fetchLatest()
            console.log("sales screen, saleId: " + saleId)
        })
    }, [])
    useEffect(() => {
        const db = getDatabase()
        const saleBookingRef = ref(db, `/companies/gonow/saleBooking`)
        return () => {
            off(saleBookingRef)
        }
    }, [])

    return (
        <Layout>
            <Sidebar />
            <Page id="events-page" className="flex-1 overflow-y-auto pb-10">
                <div className="px-8 py-4 sticky left-0">
                    <div className="text-3xl text-primary font-semibold">
                        Sales
                    </div>
                    {/* <div className="flex items-center mt-4 ">
                        <div className="flex items-center gap-3">
                            <RoundedButton
                                primary
                            >
                                Book lịch
                            </RoundedButton>
                        </div>
                      
                    </div> */}
                </div>

                <div className="px-8">
                    <SaleTable />
                </div>
            </Page>
        </Layout>
    )
}
