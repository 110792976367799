import IconClose from "common/icons/IconClose"
import { ReactNode, useCallback, useContext } from "react"
import { styled } from "styled-components"
import { dialogContext } from "./Dialog"
import classNames from "classnames"

type Props = {
    children: String | ReactNode
    grayStyle?: boolean
}

const DialogTitle = ({ children, grayStyle }: Props) => {
    const { setIsOpen } = useContext(dialogContext)
    const closeModal = useCallback(() => {
        setIsOpen(false)
    }, [])

    const styleClassName = !grayStyle
        ? "text-lg font-semibold text-gray-900 "
        : "text-md text-[#a8a8a8]  font-semibold"

    return (
        <>
            <div
                className={classNames(
                    " relative leading-6  px-6 py-3  overflow-hidden rounded-t-lg border-b border-td",
                    styleClassName
                )}
            >
                {children}
                <CloseButton onClick={closeModal}>
                    <IconClose className="icon-close text-[17px] text-[#aaa]" />
                </CloseButton>
            </div>
        </>
    )
}

export default DialogTitle

const CloseButton = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 18px;
    border-radius: 99px;
    cursor: pointer;
    top: 14px;
    padding: 4px;
    font-size: 11px;
    /* color: #aaa; */
    &:hover {
        background-color: #eee;
    }
`
