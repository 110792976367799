import classNames from "classnames"
import { CaretDropdown } from "common/components/CaretDropdown"
import { PinTab, PinTabs } from "common/components/PinTabs"
import SearchBox from "common/components/SearchBox"
import { RoundedButton } from "common/components/button/RoundedButton"
import IconEdit from "common/icons/IconEdit"
import { cellBorderColor } from "common/ui/cells/config"
import { Page } from "common/ui/styledComponents"
import { containsIgnoreCase } from "common/ui/utils"
import { UserItem } from "models/user"
import { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { AppRoutes, Role, RoleNames } from "services/constants"
import { userStore } from "stores/userStore"
import styled from "styled-components"
import { useSnapshot } from "valtio"
import EditUser from "./EditUser"
import { AddUser } from "./AddUser"
import { UserAction } from "stores/actionStores/userAction"
import { SimpleTable2 } from "common/ui/simpleTable"

const UserTable = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)

    const [searchText, setSearchText] = useState("")
    const { users } = useSnapshot(userStore)
    const [selectedItem, setSelectedItem] = useState<UserItem>()
    const [showActive, setShowActive] = useState(true)
    const [activeId, setActiveId] = useState<number>(0)

    useEffect(() => {
        UserAction.fetchLatest()
    }, [])

    const handleOpenChange = (openFlag: boolean, itemId: number) => {
        openFlag ? setActiveId(itemId) : setActiveId(0)
    }
    const handleAction = (action: string, item: UserItem) => {}
    const result = users.filter(
        (e) =>
            containsIgnoreCase(e.displayName, searchText) ||
            containsIgnoreCase(e.code, searchText)
    )
    return (
        <Page className="flex-1 overflow-y-auto">
            <div className="px-8 py-4">
                <div className="flex items-center text-[11px] font-medium">
                    <div className="text-blue">Trang chủ</div>
                    <span className="text-[#ccc] mx-2">//</span>
                    <NavLink
                        className={"hover:underline text-blue"}
                        to={`${AppRoutes.SETTINGS}`}
                    >
                        Cài đặt
                    </NavLink>
                    <span className="text-[#ccc] mx-2">//</span>
                    <span>Tài khoản</span>
                </div>
                <div className="text-xl text-primary mt-4 font-semibold mb-3">
                    Tài khoản
                </div>
                <RoundedButton primary onClick={() => setOpenAdd(true)}>
                    Thêm
                </RoundedButton>
                <div className="flex items-center gap-3 mb-3 mt-4">
                    <SearchBox
                        rounded
                        className="w-80 bg-white"
                        value={searchText}
                        onChange={setSearchText}
                        placeholder="Gõ để tìm kiếm"
                    />
                    <div className="ml-auto">
                        {/* <PinTabs>
                            <PinTab
                                onClick={() => setShowActive(true)}
                                active={showActive}
                            >
                                Khả dụng
                            </PinTab>
                            <PinTab
                                onClick={() => setShowActive(false)}
                                active={!showActive}
                            >
                                Đang khóa
                            </PinTab>
                        </PinTabs> */}
                    </div>
                </div>
                <div>
                    <HeaderWrap className="card-shadow ">
                        <div>STT</div>
                        <div className="justify-center">TÊN HIỂN THỊ</div>
                        <div className="justify-center">TÊN ĐĂNG NHẬP</div>
                        <div className="justify-center">MÃ NHÂN VIÊN</div>
                        <div className="justify-center">QUYỀN</div>
                        <div className="justify-center">&nbsp;</div>
                    </HeaderWrap>
                    {result
                        .filter(
                            (e) =>
                                containsIgnoreCase(e.displayName, searchText) ||
                                containsIgnoreCase(e.code, searchText)
                        )
                        .sort((a, b) => b.ts - a.ts)
                        .map((e, index) => (
                            <Row
                                key={e.id}
                                className={classNames(
                                    "grid-cols-[56px,1fr,160px,160px,160px,50px]",
                                    {
                                        active: e.id == activeId,
                                    }
                                )}
                            >
                                <Cell>{index + 1}</Cell>
                                <Cell>{e.displayName}</Cell>
                                <Cell>{e.userName}</Cell>
                                <Cell>{e.code}</Cell>
                                <Cell>{RoleNames[e.roleId]}</Cell>

                                <Cell className="flex justify-center ">
                                    <CaretDropdown
                                        onOpenChange={(_) =>
                                            handleOpenChange(_, e.id)
                                        }
                                        onAction={(key: string) => {
                                            handleAction(key, e)
                                        }}
                                        menus={[]}
                                    />
                                </Cell>
                            </Row>
                        ))}
                    {result.length == 0 && (
                        <Empty className="flex items-center justify-center text-sm ">
                            Không có dữ liệu
                        </Empty>
                    )}
                    {selectedItem != null && (
                        <EditUser
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            user={selectedItem}
                        />
                    )}
                    <AddUser isOpen={openAdd} setIsOpen={setOpenAdd} />
                </div>
            </div>
        </Page>
    )
}

export default UserTable

const HeaderWrap = styled.div`
    background-color: #f1f1f1;
    position: sticky;
    top: -20px;
    z-index: 10;
    flex-shrink: 0;
    font-size: 12px;
    display: grid;
    grid-template-columns: 56px 1fr 160px 160px 160px 50px;
    border-left: 1px solid #e9e9e9;
    border-top: 1px solid #e9e9e9;
    div {
        display: flex;
        align-items: center;
        padding: 8px 8px;
        font-weight: bold;
        border-right: 1px solid ${cellBorderColor};
    }
`
const Row = styled.div`
    box-sizing: border-box;
    min-height: 28px;
    border-bottom: 1px solid #e9e9e9;
    border-left: 1px solid ${cellBorderColor};
    &:nth-child(2n + 1) {
        background-color: #fdfdfc;
    }
    font-size: 13px;
    display: grid;
    &:first-of-type {
        padding-top: 2px;
    }

    grid-template-columns: 56px 1fr 160px 160px 50px;

    &:hover,
    &.active {
        background-color: #f6faff;
    }
`

const Empty = styled.div`
    height: 78px;
    color: #dedede;
    border-bottom: 1px solid #e9e9e9;
    border-left: 1px solid ${cellBorderColor};
    border-right: 1px solid ${cellBorderColor};
`

const Cell = styled.div`
    border-right: 1px solid ${cellBorderColor};
    text-align: center;
    padding: 8px 8px;
`
