import { CommonResponse } from "models/response"
import API from "./api"
import { UserItem } from "models/user"
import { ServingSale } from "common/types"

export const SaleService = {
    async getLatest(ts: number) {
        var res = await API.get<ServingSale[]>(`/sales?ts=${ts}`)
        return res.data
    },
    async add(
        item: Pick<
            UserItem,
            | "userName"
            | "displayName"
            | "password"
            | "roleId"
            | "phoneNumber"
            | "code"
        >
    ) {
        var res = await API.post<CommonResponse>(`/users`, item)
        return res.data
    },
    // async update(item: Pick<Material, "id" | "name" | "code">) {
    //     var res = await API.put<CommonResponse>(`/material-groups`, {
    //         id: item.id,
    //         code: item.code,
    //         name: item.name,
    //     })
    //     return res.data
    // },
    // async archieve(id: number) {
    //     var res = await API.delete<CommonResponse>(`/material-groups/${id}`)
    //     return res.data
    // },
}
