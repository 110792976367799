export const CLOUD_FRONT_URL = "https://d274z23b05y0oq.cloudfront.net"

export const SIDEBAR_WIDTH = 240

export const Role = {
    ADMIN: 1,
    MANAGER: 2,
    SALE: 3,
}

export const RoleNames = {
    [Role.ADMIN]: "Admin",
    [Role.MANAGER]: "Quản lý",
    [Role.SALE]: "Sale",
}

export const MessageService = {
    parseError(s: string) {
        if (s == "duplicate-name") {
            return "Tên đã tồn tại!"
        }
        if (s == "invalid-name") {
            return "Tên sai định dạng!"
        }
        return ""
    },
}

export const BookingStatus = {
    ASSIGNED: 1,
    SERVING: 2,
    BOOKED: 3,
    CANCELLED: 4,
};

export const AppRoutes = {
    NEW_EVENT: "su-kien-moi",
    REPORT: "/report",
    FEEDBACK_REPORT: "/bao-cao/report-khach-hang",
    CUSTOMERS: "/khach-hang",
    EVENT_DETAIL: "/su-kien",
    EVENT_MONTH_VIEW: "/lich-thang",
    SETTINGS: "/account-settings",
    LEADS: "/leads",
    Orders: "/orders",
}

export const quillModules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic"],
        [{ color: [] }, { background: [] }],
        ["clean"],
    ],
}

export const quillFormat = [
    "header",
    "bold",
    "italic",
    "color",
    "background",
    "list",
    "bullet",
]

export const AttachmentType = {
    LAYOUT: 1,
}
